import React from "react"
import { FaInstagram, FaFacebookF } from "react-icons/fa"

export const mainMenuItems = [
  {
    path: "/",
    title: "home",
  },
  {
    path: "/about",
    title: "about",
  },
  {
    path: "/packages",
    title: "packages",
  },
  {
    path: "/contact",
    title: "contact",
  },
]

export const socialMenuItems = [
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/hashsnapkorea/",
    name: "Instagram",
  },
  {
    icon: <FaFacebookF />,
    url: "https://www.facebook.com/HashsnapOfficial",
    name: "Facebook",
  },
  {
    icon: <div 
      style={{
        width: 36,
        height: 26,
        background: `url(https://hashsnap-static.s3.ap-northeast-2.amazonaws.com/file/hashsnap-site/logo_blog.svg) no-repeat center / contain`
      }} 
    />,
    url: "https://blog.naver.com/jetarrow_official",
    name: "Blog",
  }
]

export const footerMenuItems = [
  {
    path: "/terms",
    title: "서비스 이용약관",
  },
  {
    path: "/policy",
    title: "개인정보 처리방침",
  },
  {
    path: "/email",
    title: "이메일 무단수집거부",
  },
]
