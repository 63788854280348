import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
:root {
  --heavyWeight: 900;
  --transMed: 0.3s;
  --transSlow: 0.5s;
  --black: #1f1f1f;
  --charcoal: #3e3e3e;
  --primaryColor: #f441a5;
  --serif: "Playfair Display", serif;
  --sansSerif: "Gothic A1", sans-serif;
  --h1: 2rem;
  --h2: 1.8rem;
  --h3: 1.5rem;
  --h4: 1.25rem;
  --h5: 1.15rem;
  --h6: 1rem;
  --footerMenuItem: 0.85rem;
  --para: 1rem;
  --spacing: 1rem;

  @media (min-width: 768px) {
    --h1: 2.6rem;
    --h2: 2.4rem;
    --h3: 1.75rem;
    --h4: 1.5rem;
    --h5: 1.25rem;
    --h6: 1.1rem;
    --footerMenuItem: 1rem;
    --para: 1.1rem;
  }

  @media (min-width: 1200px) {
    --h1: 3rem;
    --h2: 2.8rem;
    --h3: 2rem;
    --h4: 1.75rem;
    --h5: 1.35rem;
    --para: 1.15rem;
  }
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  color: #fff;
  background: #050A1D;
}

video {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-container {
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
  
  @media (min-width: 1080px) {
    max-width: 1060px;
  }
}

.content-container {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  
  @media (min-width: 1080px) {
    max-width: 650px;
  }
}

.flex-container {
  display: flex;

  &.trio-block {
    flex-direction: column;
    justify-content: center;

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }
}

.section-padding {
  padding: 100px 30px;
}

.text-center {
  text-align: center;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}
`
export default GlobalStyles
