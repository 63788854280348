import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import {
  mainMenuItems,
  footerMenuItems,
  socialMenuItems,
} from "../constants/menu-items"
import styled from "styled-components"
import PropTypes from "prop-types"

const Footer = ({ Logo }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            authorSite
          }
        }
      }
    `
  )

  const footerData = data.site.siteMetadata

  return (
    <FooterStyles>
      <div className="flex">
        {Logo ? (
          <div className="brand-cont">
            {/* If there is a logo, render this */}
            {Logo && <Link to="/">
              <img src={Logo} alt={`${footerData.title} logo`} />
            </Link>}
            {/* If footer menu items are being imported, render this */}
            {footerMenuItems && (
              <ul className="footer-menu">
                {footerMenuItems.map((item, index) => (
                  <li key={`footerMenuItem${index}`}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            )}
            <address>
              회사명: (주)해시스냅 | 사업자번호 : 599-87-00608<br />
              대표자 : 이동현 | 서울특별시 강남구 역삼동 708-32 ES타워 10층 (06212)<br />
              전화: 02-6203-8600 | 팩스: 02-521-4484 | 서비스 문의: info@hashsnap.net
            </address>
          </div>
        ) : null}

        {mainMenuItems || socialMenuItems ? (
          <div className="menus-cont">
            {/* If main menu items are being imported, render this */}
            {mainMenuItems && (
              <ul className="footer-menu" style={{display: 'none'}}>
                {/* First we want to filter out the Home menu item, then display the rest of them */}
                {mainMenuItems
                  .filter(item => {
                    return item.title !== "home"
                  })
                  .map((item, index) => (
                    <li key={`menuItem${index}`}>
                      <Link to={item.path}>{item.title}</Link>
                    </li>
                  ))}
              </ul>
            )}

            {/* If social menu items are being imported, render this */}
            {socialMenuItems && (
              <ul className="footer-menu socials">
                {socialMenuItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.icon}
                        <span className="sr-only">{item.name}</span>
                      </a>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        ) : null}

        <div className="copy-cont">
          <ul className="copy">
            <li>Copyright &copy; {new Date().getFullYear()}</li>
            {/* if there is an author stated in the config, render this */}
            {footerData.author && (
              <li> {footerData.author} </li>
            )}
          </ul>
        </div>
      </div>
    </FooterStyles>
  )
}

Footer.propTypes = {
  Logo: PropTypes.string,
}

const FooterStyles = styled.footer`
  padding: 2.6rem 1.5rem;
  background-color: #000;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #fff;
  .flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  .elephone,
  address,
  li {
    color: #a8a8a8;
    font-size: 0.85rem;
    font-style: normal;
    line-height: 22px;
  }
  li {
    text-transform: capitalize;
    list-style: none;
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    @media (min-width: 768px) {
      margin-top: 10px;
      margin-bottom: 1rem;
    }
  }
  a {
    text-decoration: none;
    color: #fff;
  }

  @media (min-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  .brand-cont {
    margin-bottom: 2rem;
  }

  .brand-cont {
    width: 100%;
    @media (min-width: 768px) {
      width: 75%;
    }
    a {
      display: inline-block;
    }

    img {
      max-width: 125px;
      width: 100%;
    }
  }

  .menus-cont {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    @media (min-width: 768px) {
      width: 50%;
      border-top: 0;
    }
  }

  .footer-menu {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin: 0;
    }
    li {
      padding-left: 1rem;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .menus-cont {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    @media (min-width: 768px) {
      width: 25%;
    }
  }

  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
      justify-content: flex-end;
    }
    li {
      margin-top: 0;
      padding: 0;
      margin-right: 1.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      font-size: 24px;
    }
  }
  .copy-cont {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 2rem;
    padding-top: 2rem;
    @media (min-width: 768px) {
      border-top: none;
      padding-top: 0;
    }
    .copy {
      display: flex;
      justify-content: center;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      li {
        margin-right: 1rem;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
`

export default Footer
