import "./reset.css"
import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import SEO from "../components/seo"
import GlobalStyles from "../styles/GlobalStyles"
import TypographyStyles from "../styles/TypographyStyles"

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <GlobalStyles />
      <TypographyStyles />
      <Navbar Logo="https://hashsnap-static.s3.ap-northeast-2.amazonaws.com/file/hashsnap-site/hashsnap-ci-primary-reversed.svg" />
      <div className="layout">{children}</div>
      <Footer Logo="https://hashsnap-static.s3.ap-northeast-2.amazonaws.com/file/hashsnap-site/hashsnap-ci-primary-reversed.svg" />
    </>
  )
}

export default Layout
